<template>
    <DxLoadPanel
      :visible="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :hide-on-outside-click="true"
      shading-color="rgba(0,0,0,0.4)"
    />
    <div id="LogIn_Wrapper">
    
        <div class="LogIn_Body">
            <div class="Login_Wrapper">
                <hgroup>
                    <h1>로그인</h1>
                    <p>BIMIL 관리자 페이지입니다.</p>
                </hgroup>

                <div class="LogIn_Form">
                    <form v-on:submit.prevent>
                        <input type="text" name="userid" class="text-field" placeholder="아이디 입력" v-model="id">
                        <input type="password" name="password" class="text-field" placeholder="비밀번호 입력" v-model="password">
                        <button value="로그인" @click="LoginDebounced" class="submit-btn">로그인</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import {
    DxLoadPanel
} from 'devextreme-vue/load-panel';

export default {
    data() {
        return {
            id: '',
            password: '',
            loadingVisible: false
        }
    },
    mounted() {
        this.LoginDebounced = debounce(this.Login, 1000);
    },
    components: {
        DxLoadPanel
    },
    methods: {
        Login() {
            let data = {
                ID : this.id,
                PASSWORD : this.password,
                procName : "USP_AdminLogin"
            }
            this.loadingVisible = true;
            axios.post("https://bimilservertest.bimpeers.com/ADMIN_LOGIN", JSON.stringify(data), {
            // axios.post("https://bimilserver.bimpeers.com/ADMIN_LOGIN", JSON.stringify(data), {
            //axios.post(this.$SERVERURL + "ADMIN_LOGIN", JSON.stringify(data), {
            //axios.post("http://localhost:12008/ADMIN_LOGIN", JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.loadingVisible = false;
                if(res.data._data["length"] == 0) {
                    alert("아이디 비밀번호를 확인하세요");
                }
                else {
                    let btoken = res.data._data[0]["APIKey"]
                    //alert("로그인 성공");
                    Cookies.set('btoken', btoken, {expires: 1/8});
                    this.$router.push('/main');
                }
            })
        },
    }
}
</script>


<style scoped>
#LogIn_Wrapper {
    position:absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100vh;
    -ms-overflow-style: none; /* IE, Edge */
    scrollbar-width: none; /* Firefox */
}
.LogIn_Body {
    grid-row: 2;
    width: 100%;
    /* height: 100%; */
    max-width: 500px;
}
.Login_Wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom:160px;
}
.Login_Wrapper > hgroup {
    text-align: center;
    display: block;
}
.Login_Wrapper > hgroup h1 {
    margin: 0;
    font-size: 3.625rem;
    color: rgba(28,26,27,1);
    line-height: 1.2;
    font-weight: 300;
}

.Login_Wrapper > hgroup p {
    font-size: 1.5rem;
    font-weight:300;
    margin:18px 0 16px 0;
}

.LogIn_Div button {
    position: relative;
    top:-45px;
    right:7px;
    place-self:flex-end;
    border:0;
    background: 0;
    cursor: pointer;
}

.LogIn_Form {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LogIn_Form form {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.text-field {
    width:100%;
    height:45px;
    border:0;
    border-bottom:1px solid #ccc;
    font-size: 1.2rem;
    padding: 0 0 0 10px;
}
.submit-btn {
    width:100%;
    height:60px;
    background:#3b4353;
    border:0;
    color:#fff;
    font-size:1.5rem;
    cursor: pointer;
}
</style>
